import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import 'typeface-roboto'
import 'typeface-pt-sans'

const GStyle = createGlobalStyle`
	body.menu-open {
		overflow: hidden;
	}
`

const Container = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 20px;
	//background-color: #48b8ff;

	//position: relative;
	overflow-x: hidden;

	img {
		width: 240px;
		filter: invert(100%) sepia(25%) saturate(2%) hue-rotate(10deg)
			brightness(115%) contrast(100%);
	}
`

const Logo = styled.div`
	line-height: 1;
	align-self: center;

	a {
		text-decoration: none;
	}
`

const LogoImg = styled.h3`
	font-family: 'PT Sans', sans-serif;
	font-weight: 700;
	font-size: 48px;
	color: #0099ff;
	margin: 0;
	padding: 0;
`

const LogoText = styled.h1`
	font-family: 'PT Sans', sans-serif;
	font-weight: 500;
	font-size: 16px;
	color: black;
	margin: 0;
	padding: 0;
`

const Menu = styled.ul`
	list-style: none;
	display: flex;
	align-self: center;
	margin: 0;
	padding: 0;

	z-index: 1000;

	@media (max-width: 768px) {
		display: none;
	}
`

const MenuItem = styled.li`
	margin-right: 56px;
	font-size: 18px;

	&:last-child {
		margin-right: 0;
	}

	font-family: 'Roboto', sans-serif;
	font-weight: 500;
`

/*const MobileMenu = styled.div`
	display: none;
	position: absolute;
	width: 400px;
	height: 100%;
	background-color: white;
	border-left: 1px solid #ebf1f4;
	padding-top: 14vh;

	@media (max-width: 768px) {
		display: block;
	}

	transform-origin: 0% 0%;
	transform: translate(100vw, 0);

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`*/

const MobileMenu = styled.div`
	//background-color: red;
	height: 100%;
	overflow: hidden;
	position: absolute;
	display: block;
	top: 0;
	right: 0;

	width: 300px;
`

const MobileMenuList = styled.ul`
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: center;

	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	width: 299px;
	height: 100%;
	background-color: white;
	border-left: 1px solid #ebf1f4;
	padding-top: 28vh;

	//visibility: hidden;

	z-index: 1;

	transform-origin: 0% 0%;
	transform: translate(300px, 0);

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`

const MobileMenuItem = styled.li`
	font-size: 18px;
	margin-bottom: 8vh;

	&:last-child {
		margin-right: 0;
	}

	font-family: 'Roboto', sans-serif;
	font-weight: 500;
`

const MenuInput = styled.input`
	user-select: none;
	display: none;
	width: 40px;
	height: 32px;
	position: absolute;
	right: 28px;
	top: 28px;
	opacity: 0;
	cursor: pointer;

	z-index: 301;

	@media (max-width: 768px) {
		display: block;
	}

	&:checked ~ span {
		transform: rotate(45deg) translate(3px, -1px);
		opacity: 1;
	}

	&:checked ~ span:nth-last-child(3) {
		opacity: 0;
		transform: rotate(0deg) scale(0.2, 0.2);
	}

	&:checked ~ span:nth-last-child(2) {
		transform: rotate(-45deg) translate(1px, 4px);
		opacity: 1;
	}

	&:checked ~ ul {
		transform: none;
	}
`

const HamburgerLine = styled.span`
	width: 33px;
	height: 4px;
	margin-bottom: 5px;
	align-self: center;
	cursor: pointer;
	position: relative;
	margin-left: 232px;

	@media (max-width: 768px) {
		display: block;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
	&:first-of-type {
		margin-top: 32px;
	}

	background: black;
	border-radius: 3px;

	z-index: 300;

	transform-origin: 4px 0px;

	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
		background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

	&:first-child {
		transform-origin: 0% 0%;
	}

	&:nth-last-child(2) {
		transform-origin: 0% 100%;
	}
`

const Link = styled.a`
	color: black;
	text-decoration: none;
	cursor: pointer;
`

const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	useEffect(() => {
		document.body.classList.toggle('menu-open', isOpen)
	}, [isOpen])

	return (
		<Container>
			<Logo>
				<img src='/wonsa_logga_transp.png'></img>
			</Logo>
		</Container>
	)

	/*return (
		<Container>
			<GStyle />
			<Logo>
				<a href='/'>
					<LogoImg>CPTSD</LogoImg>
					<LogoText>WONSAs utbildningsinstitut</LogoText>
				</a>
			</Logo>
			<Menu>
				<MenuItem>
					<Link
						href='https://forms.gle/3d3oVYm7o8jsgmrP9'
						target='_blank'
					>
						Anmälan
					</Link>
				</MenuItem>
				<MenuItem>
					<Link href='mailto:info@wonsa.org'>Kontakta oss</Link>
				</MenuItem>
			</Menu>

			<MobileMenu className='mobile-menu'>
				<MenuInput
					type='checkbox'
					name='menu-toggle'
					onChange={() => {
						setIsOpen(!isOpen)
					}}
					value={isOpen}
				/>
				<HamburgerLine />
				<HamburgerLine />
				<HamburgerLine />

				<MobileMenuList>
					<MobileMenuItem>
						<Link
							href='https://forms.gle/3d3oVYm7o8jsgmrP9'
							target='_blank'
						>
							Anmälan
						</Link>
					</MobileMenuItem>
					<MobileMenuItem>
						<Link href='mailto:info@wonsa.org'>Kontakta oss</Link>
					</MobileMenuItem>
				</MobileMenuList>
			</MobileMenu>
		</Container>
	)*/
}

export default Header
